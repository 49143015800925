/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {


  $.scrollLock = ( function scrollLockSimple(){
    var locked   = false;
    var $body;
    var previous;

    function lock(){
      if( !$body ){
        $body = $( 'body' );
      }

      previous = $body.css( 'overflow' );

      $body.css( 'overflow', 'hidden' );

      locked = true;
    }

    function unlock(){
      $body.css( 'overflow', previous );

      locked = false;
    }

    return function scrollLock( on ) {
      // If an argument is passed, lock or unlock depending on truthiness
      if( arguments.length ) {
        if( on ) {
          lock();
        }
        else {
          unlock();
        }
      }
      // Otherwise, toggle
      else {
        if( locked ){
          unlock();
        }
        else {
          lock();
        }
      }
    };
  }() );

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var navbarHeight = $('header').outerHeight();
  var locked = false;
  var lastScrollTop = 0;
  var delta = 5;

  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        this.closeMenuOnClick();
        this.toggleNav();
        this.showMoreOnClick();
        //this.initAOS();
      },
      closeMenuOnClick: function () {
        if($("nav").hasClass('mobile')) {
          $("a.hamburger").removeClass('active');
          $.scrollLock( false );
          $("#top-menu-nav").removeClass('mobile');
          $("body").removeClass('overflow-hidden');
          locked = false;
        }
      },

      initAOS: function () {
        $('*[data-aos|="nojs"]').each(function(){
          var $this = $(this);
          $this.attr('data-aos',$this.attr('data-aos').replace('nojs-',''));
        });
        AOS.init({
          easing: 'ease-out-back',
          duration: 1000
        });
      },

      toggleNav: function () {
        $("a.hamburger").on('click', function () {
          $(this).toggleClass('active');
          $("#top-menu-nav").toggleClass('mobile');
          $("body").toggleClass('overflow-hidden');

          if($("nav").hasClass('mobile')) {
            $("#top-menu-nav ul").addClass('container');
            $.scrollLock( true );
            locked = true;
          } else {
            $("#top-menu-nav ul").removeClass('container');
            $.scrollLock( false );
            locked = false;
          }
        });
      },

      showMoreOnClick: function() {

        $(".paragraph-small").click(function () {
            $('.paragraph-small').toggleClass('paragraph-large');
          $(this).toggleClass('active');
        });

      },

      resizeHeaderOnScroll: function () {
        var shrink = 200;
        var header = $(".top-menu");

        if($(document).scrollTop() > shrink || locked) {
          header.addClass('shrink');
        } else {
          header.removeClass('shrink');
        }

        if(!locked) {
          var st = $(this).scrollTop();

          if(Math.abs(lastScrollTop - st) <= delta) {
            return;
          }

          if (st > lastScrollTop && st > navbarHeight){
            // Scroll Down
            $('header').removeClass('nav-down').addClass('nav-up');
          } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
              $('header').removeClass('nav-up').addClass('nav-down');
            }
          }
          lastScrollTop = st;
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {

      window.addEventListener('scroll', Sage.common.resizeHeaderOnScroll);

      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

      //toggle img for visual compsoer element: toggle-img-thumbnail
      $(".ci-thumb").on("click",function(){
        console.log("Tsg");
        var bg = $(this).css('background-image');
        bg = bg.replace('url(','').replace(')','').replace(/\"/gi, "");
        $("#change-img").css("background-image", 'url(' +bg + ')');
      });


  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
